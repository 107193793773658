<template>
    <div class="home my-5">
        <h5>客户管理<router-link :to="{path:'channel/add'}" class="float-end btn btn-primary btn-sm float-right">添加</router-link></h5>

        <div>
            <table class="table table-striped ">
                <thead class="thead-light">
                <tr>
                    <th>ID</th>
                    <th>渠道</th>
                    <th>渠道码</th>
                    <th>联系人</th>
                    <th>消耗（元）</th>
                    <th>余额（元）</th>
                    <th>添加时间</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="row in list" v-bind:key="row.index">
                    <td v-for="item in row" :key="item.index">{{ item }}</td>
                    <td>
                        <router-link :to="'/channel/' + row.id + '/edit'" class="card-link">修改</router-link>
                        <router-link :to="'/channel/' + row.id + '/recharge'" class="card-link">充值</router-link>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'List',
        components: {
        },
        created() {
            this.init();
        },
        data(){
            return {
                list: [],
            }
        },
        methods: {
            init() {
                this.list = [
                    {'id':1, 'title':'中国移动', 'code':'zgyd', 'contact':'里斯本',  'total':'100000', 'stock':'9000', 'created_at':'2022-01-28 14:00:00'},
                    {'id':2, 'title':'积分商城', 'code':'zgyd', 'contact':'里斯本',  'total':'100000', 'stock':'9000', 'created_at':'2022-01-28 14:00:00'},
                    {'id':3, 'title':'腾讯公益', 'code':'zgyd', 'contact':'里斯本',  'total':'100000', 'stock':'9000', 'created_at':'2022-01-28 14:00:00'},
                    {'id':4, 'title':'中国移动', 'code':'zgyd', 'contact':'里斯本',  'total':'100000', 'stock':'9000', 'created_at':'2022-01-28 14:00:00'},
                    {'id':5, 'title':'中国移动', 'code':'zgyd', 'contact':'里斯本',  'total':'100000', 'stock':'9000', 'created_at':'2022-01-28 14:00:00'},
                ]
            }

        }

    }
</script>